<template>
  <FocusTrap>
    <div class="card">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Terminal information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Terminal Name" v-if="terminal" v-model="terminal.name" autocomplete="off">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Serial No:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Terminal Serial No" v-if="terminal" v-model="terminal.serialno" autocomplete="off">
          </div>
        </div>

        <div class="text-right">
<!--          <button type="submit" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>-->
          <SaveButton @handle_save_action="saveDocument"/>
        </div>

      </div>
    </div>
    </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
export default {
  name: 'TerminalForm',
  components: { SaveButton },
  store,
  data () {
    return {
      terminal: JSON.parse('{"id":0,"name":"","serialno":""}')
    }
  },
  component: {

  },
  props: {
    myterminal: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"name":"","serialno":""}')
    }
  },
  beforeMount () {
    this.terminal = this.myterminal; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.terminal = JSON.parse('{"id":0,"name":"","serialno":""}')
  },
  mounted () {
    $('.form-control-select2').select2();
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('terminal_window_closed');
    },
    saveDocument () {
      const self = this;

      if (self.$data.terminal.name.toString().length < 3) {
        alert('Invalid Name');
        return
      } else if (self.$data.terminal.serialno.length < 1) {
        alert('Invalid Terminal Serial No');
        return
      }

      self.$data.terminal.brn_id =  parseInt(self.$store.state.user.branch.id);

      const requestOptions = {
        method: (self.$data.terminal.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.terminal)
      }

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/terminal`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success' });
          self.terminal = JSON.parse('{"id":0,"name":"","serialno":""}');
          self.$emit('terminal_saved',resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
