<template>
  <div class="card" id="mycard">

    <div class="card-header header-elements-sm-inline py-sm-0" >
      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Terminal's List</h5>
      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="table-responsive">
      <table id="terminal-table"
             class="table table-bordered table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">ID</th>
          <th data-formatter="runningFormatter" data-width="75">S.No</th>
          <th data-field="name" >Name</th>
          <th data-field="serialno" >Serial No</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-blocked text-danger" ></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="terminal_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="500" :height="270" @before-open="beforeOpen" @before-close="beforeClose" >
      <TerminalForm v-bind:myterminal="terminal" v-on:terminal_saved="loadData" v-on:terminal_window_closed="closeModal" ></TerminalForm>
    </vmodal>

  </div>

</template>

<script>
import TerminalForm from '@/views/xpos/terminal/TerminalForm.vue'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'TerminalView',
  components: {
    TerminalForm
  },
  store,
  data () {
    return {
      mytable: {},
      terminal: JSON.parse('{"id":  0,"name":"","serialno":""}')
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#terminal-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#terminal-table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    })

    self.loadData()
  },
  methods: {
    closeModal () {
      this.$modal.hide('terminal_window');
    },
    showModal () {
      this.$modal.show('terminal_window');
    },
    beforeOpen () {
      console.log('beforeOpen')
    },
    beforeClose () {
      this.terminal = JSON.parse('{"id":0,"name":"","serialno":""}')
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      self.$modal.hide('terminal_window');
      self.$data.mytable.bootstrapTable('load', []);

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/terminals/active/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.terminal = JSON.parse('{"id":0,"name":"","serialno":""}');


      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/terminal/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.terminal = resp.data;
          self.$modal.show('terminal_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    removeDocument (id) {
      const self = this;

      self.$data.terminal.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.terminal)
      }

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/terminal/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('terminal_window');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
